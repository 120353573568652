<template>
  <div class="bg-gray-100 text-gray-900 tracking-wider leading-normal w-full">
    <nav
      id="header"
      class="bg-white fixed w-full z-10 top-0 shadow"
    >
      <div
        class="w-full container mx-auto flex flex-wrap items-center justify-between my-4"
      >
        <div class="pl-4 md:pl-0">
          <a
            class="flex items-center text-yellow-600 text-base xl:text-xl no-underline hover:no-underline font-extrabold font-sans"
            href="#"
          >
            <svg
              class="fill-current h-6 inline-block text-yellow-600 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M16 2h4v15a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V0h16v2zm0 2v13a1 1 0 0 0 1 1 1 1 0 0 0 1-1V4h-2zM2 2v15a1 1 0 0 0 1 1h11.17a2.98 2.98 0 0 1-.17-1V2H2zm2 8h8v2H4v-2zm0 4h8v2H4v-2zM4 4h8v4H4V4z"
              />
            </svg>
            Automated screenshots
          </a>
        </div>

        <!-- <div class="pr-0 flex justify-end">
          <div class="flex relative inline-block float-right">
            <div class="relative text-sm">
              <button
                id="userButton"
                class="flex items-center mr-3 shadow bg-yellow-700 hover:bg-yellow-500 focus:shadow-outline focus:outline-none text-white text-sm md:text-base font-bold py-2 px-4 rounded"
              >
                Menu
                <svg
                  class="pl-2 h-2 fill-current text-white"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 129 129"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  enable-background="new 0 0 129 129"
                >
                  <g>
                    <path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z" />
                  </g>
                </svg>
              </button>

              <div
                id="userMenu"
                class="bg-white rounded shadow-md mt-2 mr-2 absolute mt-12 top-0 right-0 min-w-full overflow-auto z-30 invisible"
              >
                <ul class="list-reset">
                  <li>
                    <a
                      href="#"
                      class="px-4 py-2 block hover:bg-gray-400 no-underline hover:no-underline"
                    >My account</a>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="px-4 py-2 block hover:bg-gray-400 no-underline hover:no-underline"
                    >Notifications</a>
                  </li>
                  <li>
                    <hr class="border-t mx-2 border-gray-400">
                  </li>
                  <li>
                    <a
                      href="#"
                      class="px-4 py-2 block text-yellow-600 font-bold hover:bg-yellow-600 hover:text-white no-underline hover:no-underline"
                    >Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </nav>
    <!--Container-->
    <div
      class="container w-full flex flex-wrap mx-auto px-2 pt-8 lg:pt-16 mt-16"
    >
      <div class="w-full lg:w-1/5 px-6 text-xl text-gray-800 leading-normal">
        <p class="text-base font-bold py-2 lg:pb-6 text-gray-700">
          Menu
        </p>
        <div class="block lg:hidden sticky inset-0">
          <button
            id="menu-toggle"
            class="flex w-full justify-end px-3 py-3 bg-white lg:bg-transparent border rounded border-gray-600 hover:border-yellow-600 appearance-none focus:outline-none"
          >
            <svg
              class="fill-current h-3 float-right"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </button>
        </div>
        <div
          class="w-full sticky inset-0 hidden max-h-64 lg:h-auto overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:block mt-0 my-2 lg:my-0 border border-gray-400 lg:border-transparent bg-white shadow lg:shadow-none lg:bg-transparent z-20"
          style="top: 6em"
          id="menu-content"
        >
          <ul class="list-reset py-2 md:py-0">
            <li
              class="py-1 md:my-2 hover:bg-yellow-100 lg:hover:bg-transparent border-l-4 border-transparent font-bold border-yellow-600"
            >
              <a
                href="#section1"
                class="block pl-4 align-middle text-gray-700 no-underline hover:text-yellow-600"
              >
                <span class="pb-1 md:pb-0 text-sm">Section 1</span>
              </a>
            </li>
            <!-- <li class="py-1 md:my-2 hover:bg-yellow-100 lg:hover:bg-transparent border-l-4 border-transparent">
              <a
                href="#section2"
                class="block pl-4 align-middle text-gray-700 no-underline hover:text-yellow-600"
              >
                <span class="pb-1 md:pb-0 text-sm">Section 2</span>
              </a>
            </li> -->
            <!-- <li class="py-1 md:my-2 hover:bg-yellow-100 lg:hover:bg-transparent border-l-4 border-transparent">
              <a
                href="#section3"
                class="block pl-4 align-middle text-gray-700 no-underline hover:text-yellow-600"
              >
                <span class="pb-1 md:pb-0 text-sm">Section 3</span>
              </a>
            </li> -->
            <!-- <li class="py-1 md:my-2 hover:bg-yellow-100 lg:hover:bg-transparent border-l-4 border-transparent">
              <a
                href="#section4"
                class="block pl-4 align-middle text-gray-700 no-underline hover:text-yellow-600"
              >
                <span class="pb-1 md:pb-0 text-sm">Section 4</span>
              </a>
            </li> -->
            <!-- <li class="py-1 md:my-2 hover:bg-yellow-100 lg:hover:bg-transparent border-l-4 border-transparent">
              <a
                href="#section5"
                class="block pl-4 align-middle text-gray-700 no-underline hover:text-yellow-600"
              >
                <span class="pb-1 md:pb-0 text-sm">Section 5</span>
              </a>
            </li> -->
          </ul>
        </div>
      </div>

      <!--Section container-->
      <section class="w-full lg:w-4/5">
        <!--Title-->
        <h1
          class="flex items-center font-sans font-bold break-normal text-gray-700 px-2 text-xl mt-12 lg:mt-0 md:text-2xl"
        >
          Generate Screenshots
        </h1>

        <!--divider-->
        <hr class="bg-gray-300 my-12">

        <!--Title-->
        <h2
          id="section1"
          class="font-sans font-bold break-normal text-gray-700 px-2 pb-8 text-xl"
        >
          Section 1
        </h2>

        <!--Card-->
        <div class="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white">
          <t-input-group label="Website URL:">
            <t-input
              v-model="websiteUrl"
              type="url"
            />
          </t-input-group>
          <small>https://www.economist.com/leaders/2021/01/30/the-world-is-facing-an-upsurge-of-nuclear-proliferation</small>

          <t-input-group label="Creative's URL:">
            <t-input
              v-model="creativeUrl"
              type="url"
            />
          </t-input-group>
          <small>https://cdn.bannersnack.com/banners/b1kaxq2w3/embed/index.html</small>
          <t-button
            class="mt-5"
            @click="onClick"
          >
            Screenshot
          </t-button>
        </div>
        <!--/Card-->
        <div
          class="mb-10 p-8 mt-6 lg:leading-normal rounded shadow gradient3"
          v-if="imageUrl != ''"
        >
          <div
            class="screenshot-wrapper browser-mockup flex flex-1 m-6 md:px-0 md:m-12 bg-white rounded shadow-xl"
          >
            <img
              class="screenshot-image"
              :src="imageUrl"
            >
          </div>
        </div>
        <!--divider-->
        <!-- <hr class="bg-gray-300 my-12"> -->

        <!--Title-->
        <!-- <h2 class="font-sans font-bold break-normal text-gray-700 px-2 pb-8 text-xl">
          Section 2
        </h2> -->

        <!--Card-->
        <!-- <div
          id="section2"
          class="p-8 mt-6 lg:mt-0 rounded shadow bg-white"
        >
          <form>
            <div class="md:flex mb-6">
              <div class="md:w-1/3">
                <label
                  class="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4"
                  for="my-textfield"
                >
                  Text Field
                </label>
              </div>
              <div class="md:w-2/3">
                <input
                  class="form-input block w-full focus:bg-white"
                  id="my-textfield"
                  type="text"
                  value=""
                >
                <p class="py-2 text-sm text-gray-600">
                  add notes about populating the field
                </p>
              </div>
            </div>

            <div class="md:flex mb-6">
              <div class="md:w-1/3">
                <label
                  class="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4"
                  for="my-select"
                >
                  Drop down field
                </label>
              </div>
              <div class="md:w-2/3">
                <select
                  name=""
                  class="form-select block w-full focus:bg-white"
                  id="my-select"
                >
                  <option value="Default">
                    Default
                  </option>
                  <option value="A">
                    A
                  </option>
                  <option value="B">
                    B
                  </option>
                  <option value="C">
                    C
                  </option>
                </select>

                <p class="py-2 text-sm text-gray-600">
                  add notes about populating the field
                </p>
              </div>
            </div>

            <div class="md:flex mb-6">
              <div class="md:w-1/3">
                <label
                  class="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4"
                  for="my-textarea"
                >
                  Text Area
                </label>
              </div>
              <div class="md:w-2/3">
                <textarea
                  class="form-textarea block w-full focus:bg-white"
                  id="my-textarea"
                  value=""
                  rows="8"
                />
                <p class="py-2 text-sm text-gray-600">
                  add notes about populating the field
                </p>
              </div>
            </div>

            <div class="md:flex md:items-center">
              <div class="md:w-1/3" />
              <div class="md:w-2/3">
                <button
                  class="shadow bg-yellow-700 hover:bg-yellow-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  type="button"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div> -->
        <!--/Card-->

        <!--divider-->
        <!-- <hr class="bg-gray-300 my-12"> -->

        <!--Title-->
        <!-- <h2 class="font-sans font-bold break-normal text-gray-700 px-2 pb-8 text-xl">
          Section 3
        </h2> -->

        <!--Card-->
        <!-- <div
          id="section3"
          class="p-8 mt-6 lg:mt-0 rounded shadow bg-white"
        >
          <form>
            <div class="md:flex mb-6">
              <div class="md:w-1/3">
                <label
                  class="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4"
                  for="my-multiselect"
                >
                  Multi Select
                </label>
              </div>
              <div class="md:w-2/3">
                <select
                  class="form-multiselect block w-full"
                  multiple
                  id="my-multiselect"
                >
                  <option>Option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                  <option>Option 4</option>
                  <option>Option 5</option>
                </select>
                <p class="py-2 text-sm text-gray-600">
                  add notes about populating the field
                </p>
              </div>
            </div>

            <div class="md:flex md:items-center">
              <div class="md:w-1/3" />
              <div class="md:w-2/3">
                <button
                  class="shadow bg-yellow-700 hover:bg-yellow-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  type="button"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div> -->
        <!--/Card-->

        <!--divider-->
        <!-- <hr class="bg-gray-300 my-12"> -->

        <!--Title-->
        <!-- <h2 class="font-sans font-bold break-normal text-gray-700 px-2 pb-8 text-xl">
          Section 4
        </h2> -->

        <!--Card-->
        <!-- <div
          id="section4"
          class="p-8 mt-6 lg:mt-0 rounded shadow bg-white"
        >
          <form>
            <div class="md:flex mb-6">
              <div class="md:w-1/3">
                <label
                  class="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4"
                  for="my-radio"
                >
                  Radio Buttons
                </label>
              </div>
              <div class="md:w-2/3">
                <div class="mt-2">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      class="form-radio text-indigo-600"
                      name="radioOption"
                      value="A"
                    >
                    <span class="ml-2">Radio A</span>
                  </label>
                  <label class="inline-flex items-center ml-6">
                    <input
                      type="radio"
                      class="form-radio"
                      name="radioOption"
                      value="B"
                    >
                    <span class="ml-2">Radio B</span>
                  </label>
                </div>
                <p class="py-2 text-sm text-gray-600">
                  add notes about populating the field
                </p>
              </div>
            </div>

            <div class="md:flex mb-6">
              <div class="md:w-1/3">
                <label
                  class="block text-gray-600 font-bold md:text-left mb-3 md:mb-0 pr-4"
                  for="my-checkbox"
                >
                  Checkboxes
                </label>
              </div>
              <div class="md:w-2/3">
                <div>
                  <label class="inline-flex items-center">
                    <input
                      type="checkbox"
                      class="form-checkbox text-indigo-600"
                      checked
                    >
                    <span class="ml-2">Option 1</span>
                  </label>
                </div>
                <div>
                  <label class="inline-flex items-center">
                    <input
                      type="checkbox"
                      class="form-checkbox text-green-500"
                      checked
                    >
                    <span class="ml-2">Option 2</span>
                  </label>
                </div>
                <div>
                  <label class="inline-flex items-center">
                    <input
                      type="checkbox"
                      class="form-checkbox text-pink-600"
                      checked
                    >
                    <span class="ml-2">Option 3</span>
                  </label>
                </div>
                <p class="py-2 text-sm text-gray-600">
                  add notes about populating the field
                </p>
              </div>
            </div>

            <div class="md:flex md:items-center">
              <div class="md:w-1/3" />
              <div class="md:w-2/3">
                <button
                  class="shadow bg-yellow-700 hover:bg-yellow-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  type="button"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div> -->
        <!--/Card-->

        <!--divider-->
        <!-- <hr class="bg-gray-300 my-12"> -->

        <!--Title-->
        <!-- <h2 class="font-sans font-bold break-normal text-gray-700 px-2 pb-8 text-xl">
          Section 5
        </h2> -->

        <!--Card-->
        <!-- <div
          id="section5"
          class="p-8 mt-6 lg:mt-0 rounded shadow bg-white"
        >
          <blockquote class="border-l-4 border-yellow-600 italic my-4 pl-8 md:pl-12">
            Final confirmation disclaimer message etc
          </blockquote>

          <div class="pt-8">
            <button
              class="shadow bg-yellow-700 hover:bg-yellow-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded mr-4"
              type="button"
            >
              Save
            </button>

            <button
              class="shadow bg-yellow-100 hover:bg-yellow-200 focus:shadow-outline focus:outline-none text-gray-700 font-bold py-2 px-4 rounded mr-4"
              type="button"
            >
              Additional Action
            </button>

            <button
              class="shadow bg-yellow-100 hover:bg-yellow-200 focus:shadow-outline focus:outline-none text-gray-700 font-bold py-2 px-4 rounded"
              type="button"
            >
              Additional Action
            </button>
          </div>
        </div> -->
        <!--/Card-->
      </section>
      <!--/Section container-->

      <!--Back link -->
      <!-- <div class="w-full lg:w-4/5 lg:ml-auto text-base md:text-sm text-gray-600 px-4 py-24 mb-12">
        <span class="text-base text-yellow-600 font-bold">&lt;</span> <a
          href="#"
          class="text-base md:text-sm text-yellow-600 font-bold no-underline hover:underline"
        >Back link</a>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Demo",
  props: {},
  data() {
    return {
      websiteUrl: "",
      creativeUrl: "",
      imageUrl: ""
    };
  },

  methods: {
    bufferToImageUrl(buffer) {
      var arrayBufferView = new Uint8Array(buffer);
      var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(blob);
      return imageUrl;
    },
    // async getImage() {
    //   await axios
    //     .post("/.netlify/functions/inject", { creativeUrl: this.creativeUrl })
    //     .then(res => {
    //       console.log(res.data.data.data);
    //       if (res.data.data.data) {
    //         alert("not anymore!");
    //         clearInterval(this.interval);
    //         this.imageUrl = res.data.data.data;
    //         //this below is for deleting the entry from faunaDB by reference you have to just create the function for deleting
    //         /*
    //          await axios.post("/.netlify/functions/delete", {ref: res.ref}).then((res)=>{

    //          })*/
    //       }
    //     });
    // },

    async keepAlive() {
      // this.interval = setInterval(() => this.getImage(), 10000);
    },

    async onClick() {
      if (this.websiteUrl === "") return;
      const pageToScreenshot = this.websiteUrl;
      const creativeUrl = this.creativeUrl;
      const payload = { pageToScreenshot, creativeUrl };
      console.log("sent");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          "Access-Control-Allow-Origin": "*"
        }
      };
      await axios
        .post(
          //TODO: add firebase function URL,
          // "http://localhost:5001/adsnaps-2930c/us-central1/snapshot",
          payload,
          config
        )
        .then(async res => {
          console.log(res)
          this.imageUrl = res.data.screenshotImage
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style>
.screenshot-image {
  height: fit-content;
}
.screenshot-wrapper {
  overflow-y: auto;
}
</style>